import {
    IonAvatar,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonText,
    IonToolbar
} from '@ionic/react';

import {useEffect, useState} from "react";
import axios from "axios";
import config from "../config/main.config";
import "./MagazineDetail.css";
import {useParams} from "react-router-dom";
import {closeOutline, closeSharp} from "ionicons/icons";
import {useLocation} from "react-router";

interface multiLang {
    "de-DE": string,
    "de-AT": string,
    "en-EN": string
}


interface Product {
    id: string,
    name: multiLang,
    subHeader: multiLang,
    header: multiLang,
    thumbnail: multiLang,
    particularity: multiLang,
    taking: multiLang,
    whom: multiLang,
    packageSize: multiLang,
    compulsory: multiLang,
    admissionCountries: any,
    detail: multiLang,
    supplySource: multiLang,
    dosage: multiLang,
    color: "orange",
    click_a: multiLang,
    shopId: multiLang,
    indices: any,
    employeeInformation: multiLang,
    hideInApp: number
}

interface Indication {
    name: multiLang
    indices: any
}

const ALL_INDICATION = {
    name: {
        "de-DE": "-- alle Produkte --",
        "de-AT": "-- alle Produkte --",
        "en-EN": "-- all Products --"
    },
    indices: [{
        applicationAreaId: "0",
        id: "0",
        name: {"de-AT": "-- alle Produkte --", "de-DE": "-- alle Produkte --", "en-EN": "-- all Products --"}
    }]
}


const ProductsPage = (props: { importantInfo: boolean, user: { id: string, username: string, firstName: string, lastName: string, email: string } }) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [applicationAreas, setApplicationAreas] = useState<Indication[]>([]);
    const [chosenIndexValue, setChosenIndexValue] = useState("");
    const [articleIndex, setArticleIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [searchText, setSearchText] = useState("");
    const location = useLocation();

    const language = "de-DE"
    // @ts-ignore
    const {id} = useParams();

    useEffect(() => {
        getProducts();
        getIndications();
    }, []);


    function getProducts() {
        const api = axios.create({
            baseURL: config.BASE_URL,
        });
        api.get("product/all")
            .then((res) => {
                let langData = [];
                for (let i = 0; i < res.data.length; i++) {
                    let product = res.data[i];
                    if (product.admissionCountries.includes(language)) {
                        langData.push(product);
                    }
                }

                langData = langData.sort(function (a, b) {
                    if (a.name[language].toLowerCase() < b.name[language].toLowerCase()) {
                        return -1;
                    }
                    if (b.name[language].toLowerCase() < a.name[language].toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });

                setProducts(langData);
            })
            .catch((error) => {
            });
    }

    function getIndications() {
        const api = axios.create({
            baseURL: config.BASE_URL,
        });
        api.get("application/all/de-AT")
            .then((res) => {
                res.data.unshift(ALL_INDICATION)
                // @ts-ignore
                setApplicationAreas(res.data);
            })
            .catch((error) => {
            });
    }

    function getShowIndications() {
        let applicationAreasToSort = [];
        for (let i = 0; i < applicationAreas.length; i++) {
            let applicationArea = applicationAreas[i];
            for (let j = 0; j < applicationArea.indices.length; j++) {
                let indexing = applicationArea.indices[j];
                applicationAreasToSort.push(indexing.name[language]);
            }
        }

        applicationAreasToSort.sort();
        return applicationAreasToSort.map((item, i) => (<IonSelectOption
            value={item}>
            {item}
        </IonSelectOption>))

    }

    function getFilteredProducts() {
        let indexId = "";
        let found = false;
        if (chosenIndexValue.length > 0) {
            for (let i = 0; i < applicationAreas.length; i++) {
                let applicationArea = applicationAreas[i];
                for (let j = 0; j < applicationArea.indices.length; j++) {
                    if (chosenIndexValue === applicationArea.indices[j].name[language]) {
                        indexId = applicationArea.indices[j].id;
                        found = true;
                        break;
                    }
                }
                if (found) {
                    break;
                }
            }
        }

        if (indexId === "0") {
            indexId = "";
        }

        // @ts-ignore
        // @ts-ignore
        return products.map((product, index) =>
            inProductSearch(product, language) &&
            product.hideInApp !== 1 &&
            (indexId === "" || product.indices.includes(indexId))
                ?
                <IonItem
                    key={index}
                    onClick={() => {
                        setArticleIndex(index);
                        setShowModal(true);
                    }}
                    detail={false}>
                    <IonAvatar slot="start">
                        <img
                            src={config.PUBLIC_URL + product.thumbnail[language]}
                            alt={""}
                            style={{boxShadow: "1px 1px 3px 1px #ccc"}}
                        />
                    </IonAvatar>
                    <IonLabel style={{paddingBottom: "10px"}}>
                        <h2>
                            <b>{product.name[language]}</b>
                        </h2>
                        <p>
                            {getProductsHeader(product, language, props.user.id !== "")}
                        </p>
                    </IonLabel>
                </IonItem> : null
        )
    }

    function inProductSearch(product: Product, language: string) {
        // @ts-ignore
        return product.name[language].toLowerCase().includes(searchText.toLowerCase()) ||
            getProductsHeader(product, language, props.user.id !== "").toLowerCase().includes(searchText.toLowerCase())
    }

    function getProductsHeader(product: Product, language: string, isUser: boolean) {
        // @ts-ignore
        const employeeInfo = product.employeeInformation[language]
        //check it if has strong tag
        if (isUser && employeeInfo.includes("<strong>") && employeeInfo.includes("</strong>")) {
            let shortInfo = employeeInfo.substring(
                employeeInfo.indexOf("<strong>") + 8,
                employeeInfo.indexOf("</strong>")
            );

            // @ts-ignore
            return shortInfo.trim().length === 0 ? product.header[language] : shortInfo
        }

        // @ts-ignore
        return product.header[language]
    }

    // @ts-ignore
    // @ts-ignore
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader collapse="condense"/>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol style={{paddingTop: props.importantInfo ? "80px" : "20px"}}>
                            <IonText>
                                <h1 className={"page_title_header"}>
                                    Produkte
                                </h1>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="12" size-lg>
                            <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}
                                          placeholder={"Suchen"}
                                          animated/>
                            {props.user.id !== "" &&
                            <div className={"show-index"}>
                                <IonLabel>
                                    <b>
                                        Anwendungsbereiche
                                    </b>
                                </IonLabel>
                                <IonSelect value={chosenIndexValue} okText="Auswählen" cancelText="Abbrechen"
                                           onIonChange={e => setChosenIndexValue(e.detail.value)}>
                                    {getShowIndications()}
                                </IonSelect>
                            </div>

                            }
                            <IonLoading
                                cssClass='my-custom-class'
                                isOpen={products.length === 0}
                            />
                            <IonList>
                                {getFilteredProducts()}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonModal
                    isOpen={showModal}
                    swipeToClose={true}
                    onDidDismiss={() => {
                        setShowModal(false);
                    }}
                    cssClass={"article_modal"}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton
                                    onClick={() => setShowModal(false)}>
                                    <IonIcon
                                        ios={closeOutline} md={closeSharp}/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    {typeof products[articleIndex] !== "undefined" ?
                        <IonContent>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <img
                                    src={config.PUBLIC_URL + products[articleIndex].thumbnail[language]}
                                    alt={""}
                                    style={{width: "50%"}}
                                />
                            </div>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="10" style={{margin: "auto"}}>
                                        <IonText>
                                            <h1>
                                                {products[articleIndex].name[language]}
                                            </h1>
                                        </IonText>
                                        <IonText>
                                            <h3>{products[articleIndex].header[language]} </h3>
                                        </IonText>
                                        {props.user.id !== "" &&
                                        <IonText>
                                            <p dangerouslySetInnerHTML={{__html: products[articleIndex].employeeInformation[language]}}/>
                                        </IonText>}
                                        <IonText>
                                            <p><b>Details</b></p>
                                            <p dangerouslySetInnerHTML={{__html: products[articleIndex].detail[language]}}/>
                                        </IonText>
                                        {products[articleIndex].click_a[language] && products[articleIndex].click_a[language].length > 0 && !products[articleIndex].shopId[language] && products[articleIndex].shopId[language].length === 0 ?
                                            <IonButton
                                                onClick={() => window.open("https://pekana.klicka.de/" +
                                                    "pekana?" + products[articleIndex].click_a[language], "_blank")}>
                                                Jetzt bestellen & heute erhalten!
                                            </IonButton>
                                            :
                                            null
                                        }
                                        {products[articleIndex].shopId[language] && products[articleIndex].shopId[language].length > 0 ?
                                            <IonButton
                                                className={"submit-button"}
                                                onClick={() => window.open("https://shop.pekana.de/collections/all/products/" + products[articleIndex].shopId[language], "_blank")}>
                                                im Shop bestellen
                                            </IonButton>
                                            :
                                            null
                                        }
                                        <IonText>
                                            <p style={{paddingTop: "100px"}}>
                                                <b>
                                                    Pflichttext
                                                </b>
                                            </p>
                                            <p dangerouslySetInnerHTML={{__html: products[articleIndex].compulsory[language]}}/>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonContent>
                        : null}
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default ProductsPage;
