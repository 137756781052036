import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonSlide,
    IonSlides,
    IonText
} from '@ionic/react';
import './Home.css';
import {Article, Info} from "../helper/interfaces";
import config from "../config/main.config";
import {countProps, getFirstTag} from "../helper/utils";

const MAX_SHOW = 10

const Home = (props: { articles: Article[], info: Info[], importantInfo: boolean }) => {

    const slideOpts = {
        initialSlide: 0,
        speed: 400
    };
    const language = "de-DE";

    function getAllSlides(infos: Info[]) {
        let slides: any[];
        slides = [];

        for (let i = 0; i < infos.length; i++) {
            if (i === MAX_SHOW) {
                break
            }
            let info = infos[i];
            slides.push(<IonSlide key={"info" + i}>
                <IonCard
                    href={"/information?id=" + info.id}
                    style={{textAlign: "left", width: "100%"}}>
                    <div className={"image-container"}>
                        <img alt="" src={config.PUBLIC_URL + info.thumbnail}
                             className={"container-image"}/>
                    </div>
                    <IonCardHeader>
                        <IonCardSubtitle
                            color={"primary"}>{"Information".toUpperCase()}</IonCardSubtitle>
                        <IonCardTitle
                            className={"card_header"}><b>{info.title[language]}</b></IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div
                            className={"card_text"}
                            dangerouslySetInnerHTML={{__html: getFirstTag(info.text[language])}}/>
                    </IonCardContent>
                </IonCard>
            </IonSlide>)
        }


        return slides;
    }

    return (
        <IonPage>
            <IonContent >
                <IonHeader collapse="condense"/>
                {
                    countProps(props.info) === 0 ? [
                            <IonGrid>
                                <IonRow className="ion-align-items-center">
                                    <IonCol style={{paddingTop:"20px"}}>
                                        <IonText>
                                            <h1 className={"homepage_title_header"}>
                                                Willkommen
                                            </h1>
                                            <h2 className={"page_subtitle_header"}>
                                                bei PEKANA®
                                            </h2>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>,

                            <img
                                alt={""}
                                src={"/assets/pekana_pflanzen.jpeg"}
                                style={{width: "100%"}}/>,
                            <div style={{width: "100%", display: "flex", paddingTop: "20px"}}>
                                <img
                                    alt={""}
                                    src={"/assets/Logo.png"}
                                    style={{width: "80%", margin: "auto"}}/>
                            </div>,
                            <IonGrid>
                                <IonRow className="ion-align-items-center">
                                    <IonCol size="10" style={{margin: "auto", paddingTop: "50px"}}>
                                        <IonButton
                                            style={{width: "100%"}}
                                            routerLink={"/about"}>
                                            über uns
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        ]
                        :
                        <IonGrid>
                            <IonRow className="ion-align-items-center">
                                <IonCol style={{paddingTop: props.importantInfo? "80px": "20px"}}>
                                    <IonText>
                                        <h1 className={"page_title_header"}>
                                            Aktuelles
                                        </h1>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className={countProps(props.info) > 0 ? "ion-align-items-center" : "hide-div"}>
                                <IonCol>
                                    <IonSlides pager={true} options={slideOpts} style={{paddingBottom: "15px"}}>
                                        {getAllSlides(props.info)}
                                    </IonSlides>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-align-items-center">
                                <IonCol>
                                    <IonText>
                                        <h2 style={{marginLeft: "17px"}}>
                                            <b>
                                                Das PEKANA-Magazin
                                            </b>
                                        </h2>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-align-items-center">
                                <IonCol>
                                    <IonSlides pager={true} options={slideOpts} style={{paddingBottom: "15px"}}>
                                        {props.articles.map((article, index) =>
                                            <IonSlide key={"magazine" + index}>
                                                <IonCard
                                                    href={"/magazine-detail/" + article.magazineId + "?id=" + article.articleId}
                                                    style={{textAlign: "left", width: "100%"}}>
                                                    <div className={"image-container"}>
                                                        <img className={"container-image"}
                                                             src={config.PUBLIC_URL + article.thumbnail}/>
                                                    </div>
                                                    <IonCardHeader>
                                                        <IonCardSubtitle
                                                            color={"primary"}>{"Artikel".toUpperCase()}</IonCardSubtitle>
                                                        <IonCardTitle
                                                            className={"card_header"}><b>{article.title[language]}</b></IonCardTitle>
                                                    </IonCardHeader>
                                                    <IonCardContent>
                                                        <div
                                                            className={"card_text"}
                                                            dangerouslySetInnerHTML={{__html: getFirstTag(article.text[language])}}/>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonSlide>)}
                                    </IonSlides>
                                </IonCol>
                            </IonRow>
                        </IonGrid>}
            </IonContent>
        </IonPage>
    );
};

export default Home;
