/*
 * Development
 * /
export default {
    BASE_URL: "http://localhost:3000/api/",
    PUBLIC_URL: "http://localhost:3000/",
    STORAGE_KEY: "4WfG5jv3",
    STORAGE_EXPERT_KEY: "ceXsdJ0sdv",
    USER_STORAGE_KEY: "cjhSopcv0w9",
    USER_STORAGE_EXPERT_KEY: "uoNO)8cas46s"
}//*/

/*
 * Production
 */
export default {
    BASE_URL: "https://pekana.com/api/",
    PUBLIC_URL: "https://pekana.com/",
    STORAGE_KEY: "4WfG5jv3",
    STORAGE_EXPERT_KEY: "ceXsdJ0sdv",
    USER_STORAGE_KEY: "cjhSopcv0w9",
    USER_STORAGE_EXPERT_KEY: "uoNO)8cas46s"
}
// */