import {
    IonApp,
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonTitle,
    IonToast,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import '@ionic-native/core';
import {IonReactRouter} from '@ionic/react-router';
import {Redirect, Route} from 'react-router-dom';
import Login from "./pages/Login";
import Home from "./pages/Home";
import MagazinePage from "./pages/MagazinePage";
import MagazineDetail from "./pages/MagazineDetail";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/text.css';
import history from './history';
import {bagOutline, chevronForward, logInOutline, personOutline, videocamOutline} from "ionicons/icons";
import EmployeeInformation from "./pages/EmployeeInformation";
import {Provider} from "mobx-react";
import {
    countProps,
    getTokenFromLocalStorage,
    getUserFromLocalStorage,
    infosToUnreadCount,
    removeTokenFromStorage
} from "./helper/utils";
import {useEffect, useState} from "react";
import Profile from "./pages/Profile";
import firebase from "./firebase"
import axios from "axios";
import config from "./config/main.config";
import mainPackage from "../package.json";
import PasswordForgot from "./pages/PasswordForgot";
import ProductsPage from "./pages/ProductsPage";
import AboutPage from "./pages/AboutPage";
import Register from "./pages/Register";
import {Plugins, PushNotification, PushNotificationActionPerformed, PushNotificationToken} from '@capacitor/core';
import Mediathek from "./pages/Mediathek";

const {PushNotifications} = Plugins;

//tab icons
const homeIcon = "/assets/buttons/home.svg";
const informationIcon = "/assets/buttons/info.svg";
const magazineIcon = "/assets/buttons/magazine.svg";

interface AppPage {
    url: string;
    tab: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const appPages: AppPage[] = [
    {
        title: 'Produkte',
        url: '/products',
        tab: 'products',
        iosIcon: bagOutline,
        mdIcon: bagOutline
    },
    {
        title: 'Magazin',
        url: '/magazine',
        tab: 'magazine',
        iosIcon: magazineIcon,
        mdIcon: magazineIcon
    },
    {
        title: 'Home',
        url: '/home',
        tab: 'home',
        iosIcon: homeIcon,
        mdIcon: homeIcon
    },
];

const appToolbar: AppPage[] = [
    {
        title: 'Login',
        url: '/login',
        tab: 'login',
        iosIcon: logInOutline,
        mdIcon: logInOutline
    },
]

const loggedInPages: AppPage[] = [
    {
        title: 'Produkte',
        url: '/products',
        tab: 'products',
        iosIcon: bagOutline,
        mdIcon: bagOutline
    },
    {
        title: 'Magazin',
        url: '/magazine',
        tab: 'magazine',
        iosIcon: magazineIcon,
        mdIcon: magazineIcon
    },
    {
        title: 'Home',
        url: '/home',
        tab: "home",
        iosIcon: homeIcon,
        mdIcon: homeIcon
    },
    {
        title: 'Information',
        url: '/information',
        tab: 'information',
        iosIcon: informationIcon,
        mdIcon: informationIcon
    },
    {
        title: 'Media',
        url: '/mediathek',
        tab: 'mediathek',
        iosIcon: videocamOutline,
        mdIcon: videocamOutline
    },

];

const loggedInToolbar: AppPage[] = [
    {
        title: 'Profil',
        url: '/profile',
        tab: 'profile',
        iosIcon: personOutline,
        mdIcon: personOutline
    }
]

const DEFAULT_USER = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    username: ""
}

const App: React.FC = () => {

    const [user, setUser] = useState(DEFAULT_USER);
    const [show, setShow] = useState(false);
    const [importantInfo, setImportantInfo] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [magazines, setMagazines] = useState([]);
    const [media, setMedia] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState(0);
    const [info, setInfo] = useState([]);
    const [newestArticles, setNewestArticles] = useState([]);

    function getAllMagazines() {
        const api = axios.create({
            baseURL: config.BASE_URL,
        });
        api.get("magazine-article/all")
            .then((res) => {
                let returnMagazines = res.data;
                setMagazines(returnMagazines);
                //check newest articles
                if (countProps(returnMagazines) > 0) {
                    let newestMagazine = returnMagazines[0];
                    if (countProps(newestMagazine.articles) > 5) {
                        setNewestArticles(newestMagazine.articles.slice(0, 5))
                    } else {
                        setNewestArticles(newestMagazine.articles)
                    }
                }

            })
            .catch((error) => {
            });
    }

    async function getUserInformation() {
        let token = await getTokenFromLocalStorage();

        const api = axios.create({
            baseURL: config.BASE_URL,
        });
        if (token === null) {
            return new Promise(() => {
            });
        }
        return api.get("informations/user_all",
            {
                headers: {
                    "Authorization": "Bearer " + token,
                }
            })
            .then((res) => {
                setInfo(res.data.infos);
                //set unread infos
                let lolo = infosToUnreadCount(res.data.infos);
                setUnreadMessages(lolo)
                setImportantInfo(false);
                for (let i = 0; i < countProps(res.data.infos); i++) {
                    if (res.data.infos[i].important === 1 && !res.data.infos[i].read) {
                        setImportantInfo(true);
                    }
                }
                return true;
            })
            .catch((error) => {
            });
    }

    async function getMedia() {
        let token = await getTokenFromLocalStorage();

        const api = axios.create({
            baseURL: config.BASE_URL,
        });
        if (token === null) {
            return new Promise(() => {
            });
        }
        return api.get("mediathek/all",
            {
                headers: {
                    "Authorization": "Bearer " + token,
                }
            })
            .then((res) => {
                setMedia(res.data.infos);
            })
            .catch((error) => {
            });
    }

    useEffect(() => {
        if (user.id === "") {
            getUserFromLocalStorage().then((user) => {
                if (user !== null && typeof user !== "undefined") {
                    setUser(user);
                }
            });
        }
        getUserInformation();
        getAllMagazines();
        getMedia();
    }, []);

    async function setRead(id: string) {
        let token = await getTokenFromLocalStorage();
        const api = axios.create({
            baseURL: config.BASE_URL,
        });
        api.put("informations/seen",
            {
                id: id
            },
            {
                headers: {
                    "Authorization": "Bearer " + token,
                }
            })
            .then((res) => {
                history.push("/information")
                getUserInformation();
            })
            .catch((error) => {
            });
    }

    function registerToken() {
        if (isPlatform("ios")) {
            //console.log("TRY REGISTER TOKEN on iOS")
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.requestPermission().then(permission => {
                // console.log(permission);
                PushNotifications.register();

                // On succcess, we should be able to receive notifications
                PushNotifications.addListener('registration',
                    (token: PushNotificationToken) => {
                        // console.log("TOKE: ", token.value)
                        //   alert('Push registration success, token: ' + token.value);
                        const api = axios.create({
                            baseURL: config.BASE_URL,
                        });
                        api.post("/pns", {
                                pushNotificationToken: token.value
                            },
                            {
                                headers: {
                                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                                }
                            })
                            .then((res) => {
                                console.log("TOKEN SUCCESSFULLY SEND TO SERVER")
                            })
                            .catch((error) => {
                            });
                    }
                );

                // Some issue with your setup and push will not work
                PushNotifications.addListener('registrationError',
                    (error: any) => {
                        //  alert('Error on registration: ' + JSON.stringify(error));
                    }
                );

                // Show us the notification payload if the app is open on our device
                PushNotifications.addListener('pushNotificationReceived',
                    (notification: PushNotification) => {
                        // console.log("HALLO")
                        // console.log({ id: notification.id, title: notification.title, body: notification.body })
                        /*let notif = this.state.notifications;
                        notif.push({ id: notification.id, title: notification.title, body: notification.body })
                        this.setState({
                            notifications: notif
                        })*/
                    }
                );

                // Method called when tapping on a notification
                PushNotifications.addListener('pushNotificationActionPerformed',
                    (notification: PushNotificationActionPerformed) => {
                        // console.log("HALLO")
                        //console.log({ id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body })
                        /*  let notif = this.state.notifications;
                          notif.push({ id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body })
                          this.setState({
                              notifications: notif
                          })*/
                    }
                );
            });
        } else {
            try {
                console.log("TRY REGISTER TOKEN on WEB")
                console.log(firebase)
                // @ts-ignore
                const messaging = firebase.messaging();
                console.log("request permission")
                if (messaging === null) {
                    console.log("return null")
                    return null;
                }

                console.log("request permission")
                messaging.requestPermission().then((permission) => {
                    console.log("get token")
                    return messaging.getToken().then(async (currentToken: string) => {
                        if (currentToken) {
                            console.log('current token for client: ', currentToken);
                            // Track the token -> client mapping, by sending to backend server
                            // show on the UI that permission is secured
                            const api = axios.create({
                                baseURL: config.BASE_URL,
                            });
                            api.post("/pns", {
                                    pushNotificationToken: currentToken
                                },
                                {
                                    headers: {
                                        "Authorization": "Bearer " + await getTokenFromLocalStorage(),
                                    }
                                })
                                .then((res) => {
                                    console.log("TOKEN SUCCESSFULLY SEND TO SERVER")
                                })
                                .catch((error) => {
                                });
                        } else {
                            console.log('No registration token available. Request permission to generate one.');
                            // shows on the UI that permission is required
                            return null;
                        }
                    }).catch((err: any) => {
                        console.log('An error occurred while retrieving token. ', err);
                        // catch error while creating client token
                        return null;
                    });
                });
            } catch (e) {
                console.log(e)
            }
        }
        getUserInformation().then(() => {
        });
    }

    async function logout() {
        //unregister token
        try {
            let token = await getTokenFromLocalStorage();

            if (token == null) {
                await removeTokenFromStorage();
                //remove all infos
                window.location.reload();
                return
            }
            //send delete to server
            const api = axios.create({
                baseURL: config.BASE_URL,
            });
            api.delete("/pns/" + token,
                {
                    headers: {
                        "Authorization": "Bearer " + token,
                    }
                })
                .then((res) => {
                    console.log("TOKEN SUCCESSFULLY DELETED FROM SERVER")
                })
                .catch((error) => {
                });

            await removeTokenFromStorage();
            //remove all infos
            window.location.reload();
        } catch (e) {
            await removeTokenFromStorage();
            //remove all infos
            window.location.reload();
        }

    }

    return (
        <IonApp>
            <Provider>
                <IonHeader>
                    {importantInfo &&
                    <IonToolbar color={"danger"} onClick={() => history.push("/information")}>
                        <IonButtons slot="end">
                            <IonButton>
                                <IonIcon icon={chevronForward}/>
                            </IonButton>
                        </IonButtons>
                        <IonTitle>{"Wichtige Information"}</IonTitle>
                    </IonToolbar>
                    }
                    <IonToolbar>
                        <div
                            style={{
                                position: "absolute",
                                top: "0",
                                left: "5px"
                            }}>
                    <span style={{color: "lightgray", fontSize: "7pt"}}>
                        {"v " + mainPackage.version}
                    </span>
                        </div>
                        <IonButtons slot="end">
                            {(user.id !== "" ? loggedInToolbar : appToolbar).map((appPage, index) => {
                                return (<IonButton
                                    href={appPage.url}>
                                    <IonIcon ios={appPage.iosIcon} md={appPage.mdIcon}/>
                                    {appPage.tab === "information" && unreadMessages > 0 ?
                                        <IonBadge>{unreadMessages}</IonBadge>
                                        : null}
                                </IonButton>)
                            })}
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonReactRouter history={history}>
                        <IonTabs>
                            <IonRouterOutlet>
                                <Route path="/home" exact>
                                    <Home articles={newestArticles} info={info} importantInfo={importantInfo}/>
                                </Route>
                                <Route path="/about" exact>
                                    <AboutPage importantInfo={importantInfo}/>
                                </Route>
                                <Route path="/products" exact>
                                    <ProductsPage importantInfo={importantInfo} user={user}/>
                                </Route>
                                <Route path="/login" exact>
                                    <Login setUser={setUser} registerToken={registerToken}/>
                                </Route>
                                <Route path="/magazine" exact>
                                    <MagazinePage magazines={magazines} importantInfo={importantInfo}/>
                                </Route>
                                <Route path="/profile" exact>
                                    <Profile user={user} logout={logout} importantInfo={importantInfo}/>
                                </Route>
                                <Route path="/register" exact>
                                    <Register/>
                                </Route>
                                <Route path="/magazine-detail/:id" exact>
                                    <MagazineDetail importantInfo={importantInfo}/>
                                </Route>
                                <Route path="/password-forgot" exact>
                                    <PasswordForgot/>
                                </Route>
                                <Route path="/information" exact>
                                    <EmployeeInformation
                                        setUnreadMessages={setUnreadMessages} info={info} importantInfo={importantInfo}
                                        setRead={setRead} refreshArticles={getUserInformation}/>
                                </Route>
                                <Route path="/mediathek" exact>
                                    <Mediathek
                                        media={media} importantInfo={importantInfo}
                                        refreshArticles={getMedia}/>
                                </Route>
                                <Route exact path="/">
                                    <Redirect to="/home"/>
                                </Route>
                            </IonRouterOutlet>
                            <IonTabBar slot="bottom">
                                {(user.id !== "" ? loggedInPages : appPages).map((appPage, index) => {
                                    return (<IonTabButton
                                        tab={appPage.tab}
                                        href={appPage.url}>
                                        <IonIcon ios={appPage.iosIcon} md={appPage.mdIcon}/>
                                        <IonLabel>{appPage.title}</IonLabel>
                                        {appPage.tab === "information" && unreadMessages > 0 ?
                                            <IonBadge>{unreadMessages}</IonBadge>
                                            : null}
                                    </IonTabButton>)
                                })}
                            </IonTabBar>
                        </IonTabs>
                    </IonReactRouter>
                </IonContent>


                <IonToast
                    isOpen={show}
                    onDidDismiss={() => setShow(false)}
                    message={notification.body}
                    duration={200}
                />
            </Provider>
        </IonApp>
    );
};

export default App;
