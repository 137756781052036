import {
    IonAlert,
    IonButton,
    IonCol,
    IonContent,
    IonFab,
    IonGrid,
    IonHeader,
    IonInput,
    IonPage,
    IonRouterLink,
    IonRow,
    IonText
} from '@ionic/react';
import './Login.css';
import {useState} from "react";
import axios from "axios";
import config from "../config/main.config";
import {removeTokenFromStorage, setTokenLocalStorage, setUserToLocalStorage} from "../helper/utils";
import history from "../history";


const Login = (props: { setUser: (data: any) => void, registerToken: () => void }) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(false);

    function loginUser() {
        const loginData = {
            username: username,
            password: password,
        };

        const api = axios.create({
            baseURL: config.BASE_URL,
        });
        return api.post("/users/authenticate", loginData)
            .then(async (res) => {
                console.log(res.data.roles)
                if (res.data.roles.includes("Admin") || res.data.roles.includes("Mitarbeiter")) {
                    await setTokenLocalStorage(res.data.token);
                    await setUserToLocalStorage(res.data);
                    props.setUser(res.data);
                    try {
                    console.log("hallo")
                        props.registerToken();
                    } catch (e) {
                    }
                    history.push("/")

                } else {
                    setLoginError(true)
                }
            })
            .catch((error) => {
                removeTokenFromStorage();
                setLoginError(true)
            });
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader collapse="condense"/>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="10" size-lg offset="1" style={{paddingTop: "20px"}}>
                            <IonText>
                                <h1 className={"page_title_header"}>
                                    Login
                                </h1>
                            </IonText>
                            <IonInput
                                placeholder={"Nutzer"}
                                onIonChange={(e: any) => setUsername(e.target.value)}
                            />
                            <IonInput
                                placeholder={"Passwort"}
                                type={"password"}
                                onIonChange={(e: any) => setPassword(e.target.value)}/>
                            <IonButton onClick={loginUser}>Einloggen</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="10" size-lg offset="1">
                            <IonRouterLink href={"/password-forgot"}>Passwort vergessen</IonRouterLink>
                    <IonButton
                        style={{marginTop: "70px"}}
                        onClick={() => history.push("/register")}>Registrierung Fachkreis</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>


                <IonAlert
                    isOpen={loginError}
                    onDidDismiss={() => setLoginError(false)}
                    cssClass='my-custom-class'
                    header={'Fehler'}
                    message={'Beim Login ist etwas schief gelaufen'}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    )
}

export default Login;
