import {firebase} from "@firebase/app";
import '@firebase/messaging';

// Any other custom service worker logic can go here.
const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyC-0NYOL1hyDEpAYt7FIB1EmMsMH3yEpNE",
        authDomain: "pekana-141ce.firebaseapp.com",
        projectId: "pekana-141ce",
        storageBucket: "pekana-141ce.appspot.com",
        messagingSenderId: "941982799489",
        appId: "1:941982799489:web:b8986db101c1c1a1481f80",
        measurementId: "G-FD0X8M2D6N",
        vapidKey: "BJe2idYYNpmXthlhzS0IxsMhrlLB_KP2kdUNXAHkNarxjw-DUVxnZMDD2orocWxa1l-pDRt12ZlWegiPOnf-ZNE"
    }
};

firebase.initializeApp(environment.firebase);

export default firebase;

/*
export const removeToken = () => {
    if (messaging === null) {
        return null;
    }
    return messaging.deleteToken();
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload: any) => {
            resolve(payload);
        });
    });
*/