import {
    IonAlert,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText
} from '@ionic/react';
import './Login.css';
import {useState} from "react";
import axios from "axios";
import config from "../config/main.config";
import history from "../history";

const subjectOptions = [
    {text: "ApothekerIn", value: "ApothekerIn"},
    {text: "ÄrztIn", value: "Arzt/Ärztin"},
    {text: "Einzelhandel", value: "Einzelhandel"},
    {text: "Großhandel", value: "Großhandel"},
    {text: "Hebamme", value: "Hebamme"},
    {text: "HeilpraktikerIn", value: "HeilpraktikerIn"},
    {text: "Klinik", value: "Klinik"},
    {text: "LogopädIn", value: "LogopädIn"},
    {text: "MasseurIn", value: "MasseurIn"},
    {text: "PhysiotherapeutIn", value: "PhysiotherapeutIn"},
    {text: "PTA", value: "PTA"},
    {text: "TierärztIn", value: "Tierarzt/Tierärztin"},
    {text: "ZahnärztIn", value: "Zahnarzt/Zahnärztin"},
    {text: "PodologIn", value: "Podologe/Podologin"},
    {text: "TherapeutIn", value: "TherapeutIn"},
];


const PasswordForgot = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [salutation, setSalutation] = useState('');
    const [title, setTitle] = useState('');
    const [country, setCountry] = useState('');
    const [subject, setSubject] = useState('');
    const [street, setStreet] = useState('');
    const [place, setPlace] = useState('');
    const [postcode, setPostcode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [salutationError, setSalutationError] = useState(false);

    const [countryError, setCountryError] = useState(false);
    const [subjectError, setSubjectError] = useState(false);
    const [streetError, setStreetError] = useState(false);
    const [placeError, setPlaceError] = useState(false);
    const [postcodeError, setPostcodeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [mandatoryError, setMandatoryError] = useState(false);

    const [registerError, setRegisterError] = useState(false);
    const [registerSuccess, setRegisterSuccess] = useState(false);

    function hasErrors() {
        let error = false;

        if (salutation.length === 0) {
            setSalutationError(true);
            error = true;
        } else {
            setSalutationError(false);
        }
        if (firstName.length === 0) {
            setFirstNameError(true);
            error = true;
        } else {
            setFirstNameError(false);
        }
        if (lastName.length === 0) {
            setLastNameError(true)
            error = true;
        } else {
            setLastNameError(false)
        }

        if (subject.length === 0) {
            setSubjectError(true)
            error = true;
        } else {
            setSubjectError(false)
        }
        if (country.length === 0) {
            setCountryError(true)
            error = true;
        } else {
            setCountryError(false)
        }
        if (street.length === 0) {
            setStreetError(true)
            error = true;
        } else {
            setStreetError(false)
        }

        if (postcode.length === 0) {
            setPostcodeError(true)
            error = true;
        } else {
            setPostcodeError(false)
        }

        if (place.length === 0) {
            setPlaceError(true)
            error = true;
        } else {
            setPlaceError(false)
        }

        if (email.length === 0) {
            setEmailError(true)
            error = true;
        } else {
            setEmailError(false)
        }

        return error;
    }

    function sendRequest() {
        setMandatoryError(hasErrors());
        if (mandatoryError) {
            return;
        }

        let data = {
            firstName: firstName,
            lastName: lastName,
            salutation: salutation,
            title: title,
            country: country,
            subject: subject,
            street: street,
            place: place,
            postcode: postcode,
            phone: phone,
            email: email,
            proof_link: "",
            company: company,
            originLink: ""
        };

        const api = axios.create({
            baseURL: config.BASE_URL,
        });
        return api.post('expert/create', data)
            .then((res) => {
                setRegisterSuccess(true);
            })
            .catch((error) => {
                setRegisterError(true)
            });
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader collapse="condense"/>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="10" size-lg offset="1" style={{paddingTop: "20px"}}>
                            <IonText>
                                <h1 className={"page_title_header"}>
                                    Registrieren
                                </h1>
                            </IonText>
                            {mandatoryError && <label>Bitte füllen Sie die mit * gekennzeichneten Felder aus.</label>}

                            <IonItem>
                                <IonLabel
                                    color={salutationError ? "warning" : undefined}>
                                    Anrede*
                                </IonLabel>
                                <IonSelect value={salutation} okText="Auswählen" cancelText="Abbrechen"
                                           onIonChange={e => setSalutation(e.detail.value)}>
                                    <IonSelectOption value="Herr">Herr</IonSelectOption>
                                    <IonSelectOption value="Frau">Frau</IonSelectOption>
                                    <IonSelectOption value="Keine Angabe">Keine Angabe</IonSelectOption>
                                </IonSelect>
                            </IonItem>

                            <IonItem>
                                <IonLabel position="floating">Titel</IonLabel>
                                <IonInput
                                    type={"text"}
                                    onIonChange={(e: any) => setTitle(e.target.value)}/>
                            </IonItem>

                            <IonItem>
                                <IonLabel
                                    color={firstNameError ? "warning" : undefined}
                                    position="floating">Vorname*</IonLabel>
                                <IonInput

                                    type={"text"}
                                    onIonChange={(e: any) => setFirstName(e.target.value)}/>
                            </IonItem>

                            <IonItem>
                                <IonLabel
                                    color={lastNameError ? "warning" : undefined}
                                    position="floating">Nachname*</IonLabel>
                                <IonInput
                                    type={"text"}
                                    onIonChange={(e: any) => setLastName(e.target.value)}/>
                            </IonItem>

                            <IonItem>
                                <IonLabel position="floating">Firma</IonLabel>
                                <IonInput
                                    type={"text"}
                                    onIonChange={(e: any) => setCompany(e.target.value)}/>
                            </IonItem>

                            <IonItem>
                                <IonLabel
                                    color={subjectError ? "warning" : undefined}>
                                    Fachrichtung*
                                </IonLabel>
                                <IonSelect value={subject} okText="Auswählen" cancelText="Abbrechen"
                                           onIonChange={e => setSubject(e.detail.value)}>
                                    {subjectOptions.map((option, index) =>
                                        <IonSelectOption key={index}
                                                         value={option.value}>{option.text}</IonSelectOption>
                                    )}
                                </IonSelect>
                            </IonItem>

                            <IonItem>
                                <IonLabel
                                    color={countryError ? "warning" : undefined}
                                    position="floating">Land*</IonLabel>
                                <IonInput
                                    type={"text"}
                                    onIonChange={(e: any) => setCountry(e.target.value)}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel
                                    color={streetError ? "warning" : undefined}
                                    position="floating">
                                    Straße & Hausnummer*
                                </IonLabel>
                                <IonInput
                                    type={"text"}
                                    onIonChange={(e: any) => setStreet(e.target.value)}/>
                            </IonItem>

                            <IonItem>
                                <IonLabel
                                    color={postcodeError ? "warning" : undefined} position="floating">PLZ*</IonLabel>
                                <IonInput
                                    type={"text"}
                                    onIonChange={(e: any) => setPostcode(e.target.value)}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel
                                    color={placeError ? "warning" : undefined} position="floating">Ort*</IonLabel>
                                <IonInput
                                    type={"text"}
                                    onIonChange={(e: any) => setPlace(e.target.value)}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Telefon</IonLabel>
                                <IonInput
                                    type={"text"}
                                    onIonChange={(e: any) => setPhone(e.target.value)}/>
                            </IonItem>
                            <IonItem>
                                <IonLabel
                                    color={emailError ? "warning" : undefined} position="floating">Email*</IonLabel>
                                <IonInput
                                    type={"text"}
                                    onIonChange={(e: any) => setEmail(e.target.value)}/>
                            </IonItem>

                            <p style={{whiteSpace: "break-spaces"}}>
                                * Pflichtfelder müssen ausgefüllt werden <br/>
                                <br/>
                                ** Aus rechtlichen Gründen benötigen wir einen Nachweis Ihrer Tätigkeit als Apotheker,
                                Arzt oder Apotheker. Sie können den Nachweis auch separat an
                                info@pekana.de senden. <br/>
                                <br/>
                                *** Als PEKANA Fachkreis Mitglied erhalten Sie automatisch unseren exklusiven Fachkreis
                                Newsletter. Eine Abmeldung vom Newsletter ist jederzeit möglich. Details zum
                                Newsletterversand finden Sie in der Datenschutzerklärung.
                            </p>

                            <IonButton onClick={sendRequest}>Registrieren</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonAlert
                    isOpen={registerError}
                    onDidDismiss={() => setRegisterError(false)}
                    cssClass='my-custom-class'
                    header={'Fehler'}
                    message={'Beim registrieren ist etwas schief gelaufen'}
                    buttons={['OK']}
                />
                <IonAlert
                    isOpen={registerSuccess}
                    onDidDismiss={() => setRegisterSuccess(false)}
                    cssClass='my-custom-class'
                    header={'Erfolg'}
                    message={'Wir bearbeiten Ihre Registrierung. Sie erhalten eine Email mit einem Link. Die Bearbeitung kann bis zu 3 Tage dauern.'}
                    buttons={[
                        {
                            text: 'OK',
                            handler: () => {
                                history.push("/")
                            }
                        }
                    ]}
                />
            </IonContent>
        </IonPage>
    )
}

export default PasswordForgot;
