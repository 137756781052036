import {
    IonAvatar,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonText,
    IonToast,
    IonToolbar
} from '@ionic/react';
import './Home.css';
import {useEffect, useState} from "react";
import "./EmployeeInformation.css"
import config from "../config/main.config";
import {closeOutline, closeSharp} from "ionicons/icons";
import {getFirstTag} from "../helper/utils";
import {Info} from "../helper/interfaces";
import {useLocation} from "react-router";
import history from "../history";
import {RefresherEventDetail} from '@ionic/core';

const categoryOptions = [
    {key: 0, text: "-- alle Kategorien --", value: ""},
    {key: 1, text: "Bildung & Schulungen", value: "Bildung & Schulungen"},
    {key: 2, text: "Corona", value: "Corona"},
    {key: 3, text: "Datenschutz", value: "Datenschutz"},
    {key: 4, text: "Gesundheit", value: "Gesundheit"},
    {key: 5, text: "IT-Sicherheit", value: "IT-Sicherheit"},
    {key: 6, text: "Mitarbeitende", value: "Mitarbeitende"},
    {key: 7, text: "Nachhaltigkeit", value: "Nachhaltigkeit"},
    {key: 8, text: "PEKANA weltweit", value: "PEKANA weltweit"},
    {key: 9, text: "Produkte", value: "Produkte"},
    {key: 10, text: "Rund um PEKANA", value: "Rund um PEKANA"},
    {key: 11, text: "Werbemittel & Veranstaltungen", value: "Werbemittel & Veranstaltungen"},
]


const EmployeeInformation = (props: { setUnreadMessages: (data: any) => void, info: Info[], setRead: (data: any) => void, refreshArticles: () => Promise<any>, importantInfo: boolean }) => {

    const [showModal, setShowModal] = useState(false);
    const [articleIndex, setArticleIndex] = useState(0);
    const [show, setShow] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [chosenIndexValue, setChosenIndexValue] = useState("");
    const language = "de-DE";
    const location = useLocation();


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let infoId = params.get('id');
        if (typeof infoId === "string") {
            for (let i = 0; i < props.info.length; i++) {
                if (props.info[i].id === infoId) {
                    setArticleIndex(i);
                    setShowModal(true);
                    break;
                }
            }
        }

    }, [props.info]);

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        props.refreshArticles().then(() => {
                event.detail.complete()
                setTimeout(
                    () => setShow(true),
                    400
                )
            }
        )
    }

    function getReadableDate(dateString: string) {
        let date = new Date(dateString);

        return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    }

    function getContent() {
        if (typeof props.info[articleIndex] === "undefined") {
            return <IonContent/>
        } else {
            return <IonContent>
                <img
                    src={config.PUBLIC_URL + props.info[articleIndex].thumbnail}
                    alt={""}
                    style={{width: "100%"}}
                />
                <IonGrid>
                    <IonRow>
                        <IonCol size="10" style={{margin: "auto"}}>
                            <IonText>
                                <h2 className={"detail_header"}>
                                    {props.info[articleIndex].title[language]}
                                </h2>
                            </IonText>
                            <IonText>
                                <p>{getReadableDate(props.info[articleIndex].createdAt)}</p>
                            </IonText>
                            <IonText>
                                <p dangerouslySetInnerHTML={{__html: props.info[articleIndex].text[language]}}/>
                            </IonText>
                            <IonButton
                                onClick={() => {
                                    props.setRead(props.info[articleIndex].id);
                                    setShowModal(false)
                                }}>Gelesen</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        }
    }

    function getShowIndications() {
        return categoryOptions.map((item, i) => (<IonSelectOption
            value={item.value}>
            {item.text}
        </IonSelectOption>))
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent/>
                </IonRefresher>
                <IonHeader collapse="condense"/>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol style={{paddingTop: props.importantInfo ? "80px" : "20px"}}>
                            <IonText>
                                <h1 className={"page_title_header"}>
                                    Informationen
                                </h1>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol>
                            <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}
                                          placeholder={"Suchen"}
                                          animated/>
                            <div className={"show-index"}>
                                <IonLabel>
                                    <b>
                                        Kategorie
                                    </b>
                                </IonLabel>
                                <IonSelect value={chosenIndexValue} okText="Auswählen" cancelText="Abbrechen"
                                           onIonChange={e => setChosenIndexValue(e.detail.value)}>
                                    {getShowIndications()}
                                </IonSelect>
                            </div>
                            <IonList>
                                {props.info.map((info, index) =>
                                    (info.title[language].toLowerCase().includes(searchText.toLowerCase()) ||
                                        info.text[language].toLowerCase().includes(searchText.toLowerCase())) &&
                                    info.category.includes(chosenIndexValue) ?
                                        <IonItem
                                            key={index}
                                            onClick={() => {
                                                setArticleIndex(index);
                                                setShowModal(true);
                                            }}>
                                            <IonAvatar slot="start">
                                                <img
                                                    src={config.PUBLIC_URL + info.thumbnail}
                                                    alt={""}
                                                    style={{boxShadow: "1px 1px 3px 1px #ccc"}}
                                                />
                                                {!info.read ?
                                                    <div className={"not_seen"}/>
                                                    : null
                                                }
                                            </IonAvatar>
                                            <IonLabel style={{paddingBottom: "10px"}}>
                                                <h2>
                                                    <b>{info.title[language]}</b>
                                                </h2>
                                                <p>{getReadableDate(info.createdAt)}</p>
                                                <p>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: getFirstTag(info.text[language])}}/>
                                                </p>
                                                {info.important === 1 ?
                                                    <p className={info.read ? "" : "important"}><b>Wichtige
                                                        Information</b>
                                                    </p>
                                                    : null
                                                }
                                            </IonLabel>
                                        </IonItem>
                                        : null
                                )}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonModal
                    isOpen={showModal}
                    swipeToClose={true}
                    onDidDismiss={() => {
                        setShowModal(false);
                        history.push("/information")
                    }}
                    cssClass={"article_modal"}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton
                                    onClick={() => setShowModal(false)}>
                                    <IonIcon
                                        ios={closeOutline} md={closeSharp}/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    {getContent()}

                </IonModal>
                <IonToast
                    isOpen={show}
                    onDidDismiss={() => setShow(false)}
                    message={"Informationen befinden sich auf dem neuesten Stand"}
                    duration={400}
                />
            </IonContent>
        </IonPage>
    );
};

export default EmployeeInformation;
