import {
    IonAvatar,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonText
} from '@ionic/react';
import {logOutOutline, logOutSharp} from "ionicons/icons";
import {useEffect} from "react";
import {getUserFromLocalStorage} from "../helper/utils";
import history from "../history";


const Profile = (props: { user: { id: string, username: string, firstName: string, lastName: string, email: string }, logout: () => void, importantInfo: boolean }) => {

    useEffect(() => {
        getUserFromLocalStorage().then(user => {
            if (user == null || typeof user == "undefined") {
                history.push("/")
            }
        })
    }, []);

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader collapse="condense"/>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="10" size-lg offset="1"
                                style={{paddingTop: props.importantInfo ? "80px" : "20px"}}>
                            <IonText>
                                <h1 className={"page_title_header"}>
                                    Profil
                                </h1>
                            </IonText>
                            <IonAvatar style={{margin: "auto"}}>
                                <img
                                    alt={""}
                                    src={"/assets/Icon_Kontakt_App.png"}
                                    style={{boxShadow: "1px 1px 3px 1px #ccc"}}/>
                            </IonAvatar>
                            <p><b>Name: </b>{props.user.firstName + " " + props.user.lastName}</p>
                            <p><b>Email: </b>{props.user.email}</p>
                            <p><b>Username: </b>{props.user.username}</p>

                            <IonButton
                                onClick={() => {
                                    props.logout();
                                }}>
                                Logout
                                <IonIcon slot={"end"} ios={logOutOutline} md={logOutSharp}/>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    )
}

export default Profile;
