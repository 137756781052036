import {IonButton, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonText, isPlatform} from '@ionic/react';
import "./MagazineDetail.css";


const AboutPage = (props: { importantInfo: boolean }) => {


    // @ts-ignore
    // @ts-ignore
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader collapse="condense"/>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol style={{paddingTop: props.importantInfo ? "80px" : "20px"}}>
                            <IonText>
                                <h1 className={"page_title_header"}>
                                    Über uns
                                </h1>
                                <p>
                                    PEKANA ist ein mittelständisches Familienunternehmen in Kißlegg im Allgäu, das von
                                    Mitgründerin Katharina Beyersdorff und ihrem Sohn Dr. Marius Beyersdorff geleitet
                                    wird. Im Jahr 1980 haben Katharina und Dr. Peter Beyersdorff die PEKANA
                                    Naturheilmittel GmbH gegründet. Als Hersteller homöopathisch-spagyrischer
                                    Arzneimittel fühlen wir uns der Traditionellen Europäischen Medizin und modernen
                                    Qualitätsstandards gleichermaßen verpflichtet.
                                    <br/>
                                    <br/>

                                    Seit Anfang an stellen wir homöopathisch-spagyrische Komplexmittel her, die
                                    mittlerweile auf der ganzen Welt nachgefragt werden. Die jeweils individuelle
                                    Zusammensetzung des Arzneimittels ist abgestimmt auf die Anforderungen der meist
                                    von
                                    mehreren Faktoren ausgelösten Erkrankungen. Das Besondere an unseren
                                    Arzneimitteln
                                    ist das eigens von Dr. Peter Beyersdorff entwickelte spagyrische
                                    Herstellungsverfahren. Es folgt der Erkenntnis der Therapiemethode, die
                                    Vitalenergie
                                    der Heilpflanzen vollumfänglich zu bewahren. Dies schafft die Grundlage für ein
                                    ganzheitlich einsetzbares homöopathisch-spagyrisches Therapiekonzept. Die
                                    Herstellung aller PEKANA Arzneimittel erfolgt ausschließlich in Kißlegg im
                                    Allgäu
                                    nach modernsten Qualitätsstandards und einer strengen pharmazeutischen
                                    Qualitätskontrolle.

                                    <br/>
                                    <br/>
                                    Die PEKANA Spagyrik wurde 1991 in das 1. amtliche Homöopathische Arzneibuch
                                    Deutschlands übernommen und 1995 im Rahmen des Innovationspreises des Landes
                                    Baden-Württemberg für beispielhafte Leistungen ausgezeichnet. Seit dem Jahr 2000
                                    besitzt PEKANA das Zertifikat „Good Manufacturing Practice“ (GMP), das weltweit
                                    anerkannte Qualitätssicherung bezeugt. 2008 folgte die erfolgreiche Inspektion
                                    durch
                                    die amerikanische Gesundheitsbehörde „Food and Drug Administration“ (FDA). Durch
                                    die
                                    erfolgreiche „AEO F“-Zertifizierung durch den Zoll führt PEKANA seit dem 1.
                                    Januar
                                    2012 den Status des „zugelassenen Wirtschaftsbeteiligten“. Das „AEO
                                    F“-Zertifikat
                                    ist ein internationales Qualitätssiegel.

                                    <br/>
                                    <br/>
                                    <b>
                                        Auszeichnung im Bereich digitale Arbeitswelt <br/>
                                    </b>
                                    2020 wurde PEKANA mehrfach für besonders gute Leistung ausgezeichnet. Im Bereich
                                    digitaler Fortschritt erhielt PEKNA den Award „familyNET 4.0 –
                                    Unternehmenskultur in
                                    einer digitalen Arbeitswelt“, verliehen durch das Ministerium für Wirtschaft,
                                    Arbeit
                                    und Wohnungsbau Baden-Württemberg und die Arbeitgeber Baden-Württemberg.
                                    Ausgezeichnet wurde PEKANA in der Kategorie mittelständische Unternehmen mit
                                    50-250
                                    Beschäftigte für besonders innovative und nachhaltige Angebote und Maßnahmen in
                                    der
                                    digitalen Arbeitswelt.

                                    <br/>
                                    <br/>
                                    <b>
                                        Auszeichnung für betriebliche Altersvorsorge <br/>
                                    </b>
                                    Eine weitere Auszeichnung erhielt PEKANA für sein besonderes soziales und
                                    langjähriges Engagement von der Privaten Akademie zur Förderung der
                                    betrieblichen
                                    Altersversorgung GmbH & Co. KG aus Seefeld. Seit der Gründung ist PEKANA
                                    soziales
                                    Engagement und Fürsorge für die Mitarbeiterinnen und Mitarbeiter ein großes
                                    Anliegen. Mit dieser Auszeichnung wurde das Modell der betrieblichen
                                    Altersvorsorge,
                                    welches bei PEKANA weit über den üblichen Rahmen der sozialen Verantwortung
                                    hinausgeht, prämiert.
                                </p>
                                <IonButton
                                    onClick={() => {
                                        isPlatform("ios")?
                                            window.open(encodeURI('https://maps.apple.com/?address=Raiffeisenstraße 15, 88353 Kißlegg, Deutschland&auid=13289134758012791233&ll=47.771213,9.894340&lsp=9902&q=PEKANA-Naturheilmittel&_ext=CiwKBQgEEIEBCgQIBRADCgUIBhCfAQoECAoQAAoECFIQAQoECFUQEQoECFkQBBImKUO1d+0j4kdAMbmgZvt6xiNAOcGKnUlK40dAQQdkB+JSzSNAUAQ= '), '_system', 'location=no'):
                                            window.open(encodeURI('https://www.google.com/maps/place/PEKANA+Naturheilmittel+GmbH/@47.77129,9.8923413,17z/data=!4m5!3m4!1s0x479b98f66ca5fca3:0x845e09d1b6f5cf51!8m2!3d47.77129!4d9.89453'), '_system', 'location=no')
                                    }}>
                                    hier finden Sie uns
                                </IonButton>
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default AboutPage;
