import config from "../config/main.config";
import {Storage} from '@ionic/storage';

export async function setTokenLocalStorage(token) {

    const store = new Storage();
    await store.create();

    let object = {
        "token": token,
        timestamp: new Date().getTime()
    };

    await store.set(config.STORAGE_KEY, JSON.stringify(object));
}


export async function setUserToLocalStorage(user) {
    const store = new Storage();
    await store.create();

    let object = {
        "user": user,
        timestamp: new Date().getTime()
    };

    await store.set(config.USER_STORAGE_KEY, JSON.stringify(object));
}

export async function getUserFromLocalStorage() {
    const store = new Storage();
    await store.create();

    let object = JSON.parse(await store.get(config.USER_STORAGE_KEY));

    if (object === null) {
        return null;
    }

    let oneDayAfterTokenDate = new Date(object.timestamp);
    oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

    //if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
        return object.user;
   /* } else {
        await removeTokenFromStorage();
        return null;
    }*/
}

export async function removeTokenFromStorage() {
    const store = new Storage();
    await store.create();
    await store.remove(config.STORAGE_KEY);
    await store.remove(config.USER_STORAGE_KEY);

}

export async function getTokenFromLocalStorage() {
    const store = new Storage();
    await store.create();

    let object = JSON.parse(await store.get(config.STORAGE_KEY));

    if (object === null) {
        return null;
    }

    let oneDayAfterTokenDate = new Date(object.timestamp);
    oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

   // if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
        return object.token;
  /*  } else {
        await removeTokenFromStorage();
        return null;
    }*/

}

export function infosToUnreadCount(info) {
    let unread = 0;
    for (let i = 0; i < info.length; i++) {
        if (!info[i].read)
            unread++;
    }
    return unread;
}

export function countProps(obj) {
    let count = 0;
    for (let p in obj) {
        obj.hasOwnProperty(p) && count++;
    }
    return count;
}

export function getFirstTag(html, maxLength = null) {
    if (html.includes("<p>") && html.includes("</p>")) {
        let retHtml = html.substr(html.indexOf("<p>"), html.indexOf("</p>") + 4);
        if (maxLength === null || retHtml <= maxLength + 4) {
            return html.substr(html.indexOf("<p>"), html.indexOf("</p>") + 4)
        } else {
            return retHtml.substr(0, maxLength) + "...</p>";
        }
    } else return "";
}