import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonText
} from '@ionic/react';

import { chevronForward, chevronForwardSharp} from 'ionicons/icons';
import {Magazine} from "../helper/interfaces";

const MagazinePage = (props: { magazines: Magazine[], importantInfo:boolean }) => {

    const language = "de-DE"

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader collapse="condense"/>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol style={{paddingTop: props.importantInfo? "80px": "20px"}}>
                            <IonText>
                                <h1 className={"page_title_header"}>
                                    Magazin
                                </h1>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="12" size-lg>
                            <IonList>
                                {props.magazines.map((magazine, index) => {
                                  return magazine.admissionCountries.includes(language)?
                                        <IonItem
                                            key={index}
                                            routerLink={"magazine-detail/" + magazine.magazineId}
                                            routerDirection="forward"
                                            detail={false}>
                                            <IonLabel>{magazine.name[language]}</IonLabel>
                                            <IonIcon slot="end" ios={chevronForward} md={chevronForwardSharp}/>
                                        </IonItem>
                                       : null
                                })}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default MagazinePage;
