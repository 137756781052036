import {
    IonAvatar,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRow,
    IonText,
    IonToolbar
} from '@ionic/react';

import {useEffect, useState} from "react";
import axios from "axios";
import config from "../config/main.config";
import "./MagazineDetail.css";
import {useParams} from "react-router-dom";
import {chevronBack, closeOutline, closeSharp} from "ionicons/icons";
import {useLocation} from "react-router";
import history from "../history";
import {getFirstTag} from "../helper/utils";

const MagazineDetail = (props: { importantInfo: boolean }) => {
    const [magazine, setMagazine] = useState({
        name: {
            "de-DE": ""
        },
        articles: [{
            articleId: "",
            title: {
                "de-DE": ""
            },
            teaser: {
                "de-DE": ""
            },
            text: {
                "de-DE": "<p></p>"
            },
            thumbnail: ""
        }]
    });
    const [articleIndex, setArticleIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();

    const language = "de-DE"
    // @ts-ignore
    const {id} = useParams();

    useEffect(() => {
        getMagazine(id);
    }, []);


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let articleId = params.get('id');
        if (typeof articleId === "string") {
            for (let i = 0; i < magazine.articles.length; i++) {
                if (magazine.articles[i].articleId === articleId) {
                    setArticleIndex(i);
                    setShowModal(true);
                    break;
                }
            }
        }

    }, [magazine]);

    function getMagazine(id: string) {
        const api = axios.create({
            baseURL: config.BASE_URL,
        });
        api.get("magazine-article/magazine/" + id)
            .then((res) => {
                setMagazine(res.data);
            })
            .catch((error) => {
            });
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonToolbar onClick={() => history.goBack()} style={{paddingTop: props.importantInfo ? "80px" : "20px"}}>
                    <IonButtons slot="start">
                        <IonButton>
                            <IonIcon icon={chevronBack}/>
                            Zurück
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonHeader collapse="condense"/>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol>
                            <IonText>
                                <h1 className={"page_title_header"}>
                                    {magazine.name[language]}
                                </h1>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="12" size-lg>
                            <IonList>
                                {magazine.articles.map((article, index) =>
                                    <IonItem
                                        key={index}
                                        onClick={() => {
                                            setArticleIndex(index);
                                            setShowModal(true);
                                        }}
                                        detail={false}>
                                        <IonAvatar slot="start">
                                            <img
                                                src={config.PUBLIC_URL + article.thumbnail}
                                                alt={""}
                                                style={{boxShadow: "1px 1px 3px 1px #ccc"}}
                                            />
                                        </IonAvatar>
                                        <IonLabel style={{paddingBottom: "10px"}}>
                                            <h2>
                                                <b>{article.title[language]}</b>
                                            </h2>
                                            <p>
                                                <div
                                                    dangerouslySetInnerHTML={{__html: getFirstTag(article.text[language])}}/>
                                            </p>
                                        </IonLabel>
                                    </IonItem>
                                )}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonModal
                    isOpen={showModal}
                    swipeToClose={true}
                    onDidDismiss={() => {
                        setShowModal(false);
                        history.push("/magazine-detail/" + id)
                    }}
                    cssClass={"article_modal"}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton
                                    onClick={() => setShowModal(false)}>
                                    <IonIcon
                                        ios={closeOutline} md={closeSharp}/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <img
                            src={config.PUBLIC_URL + magazine.articles[articleIndex].thumbnail}
                            alt={""}
                            style={{width: "100%"}}
                        />
                        <IonGrid>
                            <IonRow>

                                <IonCol size="10" style={{margin: "auto"}}>
                                    <IonText>
                                        <h1>
                                            {magazine.articles[articleIndex].title[language]}
                                        </h1>
                                    </IonText>
                                    <IonText>
                                        <h3>{magazine.articles[articleIndex].teaser[language]} </h3>
                                    </IonText>
                                    <IonText>
                                        <p dangerouslySetInnerHTML={{__html: magazine.articles[articleIndex].text[language]}}/>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default MagazineDetail;
