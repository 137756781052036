import {
    IonAlert,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonPage,
    IonRow,
    IonText
} from '@ionic/react';
import './Login.css';
import {useState} from "react";
import axios from "axios";
import config from "../config/main.config";
import history from "../history";


const PasswordForgot = () => {

    const [email, setEmail] = useState('');
    const [loginError, setLoginError] = useState(false);
    const [forgotSuccess, setForgotSuccess] = useState(false);

    function sendRequest() {

        const api = axios.create({
            baseURL: config.BASE_URL,
        });
        return api.post('expert/forgot', {email: email})
            .then((res) => {
                setForgotSuccess(true);
            })
            .catch((error) => {
                setLoginError(true)
            });
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader collapse="condense"/>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="10" size-lg offset="1" style={{paddingTop:"20px"}}>
                            <IonText>
                                <h1 className={"page_title_header"}>
                                    Passwort vergessen
                                </h1>
                            </IonText>
                            <IonInput
                                placeholder={"Email"}
                                type={"email"}
                                onIonChange={(e: any) => setEmail(e.target.value)}/>
                            <IonButton onClick={sendRequest}>Senden</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonAlert
                    isOpen={loginError}
                    onDidDismiss={() => setLoginError(false)}
                    cssClass='my-custom-class'
                    header={'Fehler'}
                    message={'Beim Login ist etwas schief gelaufen'}
                    buttons={['OK']}
                />
                <IonAlert
                    isOpen={forgotSuccess}
                    onDidDismiss={() => setForgotSuccess(false)}
                    cssClass='my-custom-class'
                    header={'Erfolg'}
                    message={'Sie erhalten eine Email mit einem Link'}
                    buttons={[
                        {
                            text: 'OK',
                            handler: () => {
                                history.push("/login")
                            }
                        }
                    ]}
                />
            </IonContent>
        </IonPage>
    )
}

export default PasswordForgot;
