import {
    IonAvatar,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSearchbar,
    IonText,
    IonToast,
    IonToolbar
} from '@ionic/react';
import './Home.css';
import {useEffect, useState} from "react";
import "./EmployeeInformation.css"
import {closeOutline, closeSharp, play} from "ionicons/icons";
import {Media} from "../helper/interfaces";
import {useLocation} from "react-router";
import history from "../history";
import {RefresherEventDetail} from '@ionic/core';
import config from "../config/main.config";
import ReactPlayer from "react-player";


const Mediathek = (props: { media: Media[], refreshArticles: () => Promise<any>, importantInfo: boolean }) => {

    const [showModal, setShowModal] = useState(false);
    const [articleIndex, setArticleIndex] = useState(0);
    const [show, setShow] = useState(false);
    const [searchText, setSearchText] = useState("");
    const language = "de-DE";
    const location = useLocation();


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let infoId = params.get('id');
        if (typeof infoId === "string") {
            for (let i = 0; i < props.media.length; i++) {
                if (props.media[i].id === infoId) {
                    setArticleIndex(i);
                    setShowModal(true);
                    break;
                }
            }
        }

    }, [props.media]);

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        props.refreshArticles().then(() => {
                event.detail.complete()
                setTimeout(
                    () => setShow(true),
                    400
                )
            }
        )
    }

    function getReadableDate(dateString: string) {
        let date = new Date(dateString);
        return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    }

    function getContent() {
        if (typeof props.media[articleIndex] === "undefined") {
            return <IonContent/>
        } else {
            return <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size="10" style={{margin: "auto"}}>
                            <IonText>
                                <h2 className={"detail_header"}>
                                    {props.media[articleIndex].title[language]}
                                </h2>
                            </IonText>
                            <IonText>
                                <p>{getReadableDate(props.media[articleIndex].createdAt)}</p>
                            </IonText>
                            <ReactPlayer
                                playing
                                loop
                                volume={1}
                                controls={true}
                                width={"100%"}
                                height={"auto"}
                                url={config.PUBLIC_URL + props.media[articleIndex].link}/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        }
    }


    return (
        <IonPage>
            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent/>
                </IonRefresher>
                <IonHeader collapse="condense"/>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol style={{paddingTop: props.importantInfo ? "80px" : "20px"}}>
                            <IonText>
                                <h1 className={"page_title_header"}>
                                    Mediathek
                                </h1>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol>
                            <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}
                                          placeholder={"Suchen"}
                                          animated/>
                            <IonList>
                                {props.media.map((video, index) =>
                                    video.title[language].toLowerCase().includes(searchText.toLowerCase()) ?
                                        <IonItem
                                            key={index}
                                            onClick={() => {
                                                setArticleIndex(index);
                                                setShowModal(true);
                                            }}>
                                            <IonAvatar slot="start">
                                                <img
                                                    src={config.PUBLIC_URL + (video.thumbnail? video.thumbnail: "images/blog-images/Bild_PEKANA_Button_2.png")}
                                                    alt={""}
                                                    style={{boxShadow: "1px 1px 3px 1px #ccc"}}
                                                />
                                            </IonAvatar>
                                            <IonLabel style={{paddingBottom: "10px"}}>
                                                <h2>
                                                    <b>{video.title[language]}</b>
                                                </h2>
                                                <p>{getReadableDate(video.createdAt)}</p>
                                            </IonLabel>
                                        </IonItem>
                                        : null
                                )}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonModal
                    isOpen={showModal}
                    swipeToClose={true}
                    onDidDismiss={() => {
                        setShowModal(false);
                        history.push("/mediathek")
                    }}
                    cssClass={"article_modal"}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton
                                    onClick={() => setShowModal(false)}>
                                    <IonIcon
                                        ios={closeOutline} md={closeSharp}/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    {getContent()}

                </IonModal>
                <IonToast
                    isOpen={show}
                    onDidDismiss={() => setShow(false)}
                    message={"Informationen befinden sich auf dem neuesten Stand"}
                    duration={400}
                />
            </IonContent>
        </IonPage>
    );
};

export default Mediathek;
